import _ from 'lodash';
import renderHTML from 'react-render-html';

let translations = {};
let language = 'en';


export const updateTransalation = (key, data) => translations[key] = data;
export const updateLanguage = (lang) => language = lang; 

function evalTemplate(strans, params) {
    let tString = strans;
    if (_.isObject(params) && _.isObject(params.S)) {
        Object.keys(params.S).forEach((k) => {
            tString = tString.replace(`\${S.${k}}`, params.S[k]);
        });
    }
    return tString;
}

export function i18Get(key, S) {
  //  let translations = Store.getState().translations
    const trans = translations[language];
    const dev = translations['dev'];
    try {
        let transText = key;
        if (!_.isEmpty(trans) && key in trans) {
            transText = trans[key];
        } else if (key in dev) {
            transText = dev[key];
        }
        return renderHTML(evalTemplate(transText, { S }));
    } catch (err) {
    }
    return key;
}