import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import store from '../../store';

const dateFormat = 'DD/MMM/YY';
const timeFormat = 'HH:mm';
export default function apiHeaders () {
	const state = store.getState();
	return {headers: state.user.headers, ...{ withCredentials: true }};
}

export function formatDate(time) {
	return moment(time).format('DD/MMM/YY')
}
export function formatApiDate(time) {
	return moment(time).format('YYYY-MM-DD')
}
export function formatDateTime(time) {
	return moment(time).format('DD/MMM/YY HH:mm')
}
export function formatApiDateTime(time) {
	return moment(time).format('YYYY-MM-DD HH:mm')
}
export function formatTime(time) {
	return moment(time).format('HH:mm')
}
export function deFormatISODateTime(dString, tString) {
	const d = moment(`${dString}T${tString}`, `${dateFormat}T${timeFormat}`);
	return d.isValid() ? d.format() : null;
}

export function checkUrlParameter(search, pName) {
	let result = '',
	tmp = [];
	search.substr(1).split('&')
		.forEach((item) => {
		tmp = item.split("=");
		if (tmp[0] === pName) result = decodeURIComponent(tmp[1]);
	});
	if(typeof result !== 'undefined') {
		return result
	}
	return '';
}

export function getTrans(obj, key, decode = true) {
    const lang = store.getState().translations.language;
    let Trans;
    if (!_.isArray(obj.translations)) {
        Trans = obj.translations;
    }
    Trans = _.find(obj.translations, o => o.language === lang);
    const translated = Trans && key in Trans && Trans[key] ? Trans[key] : obj[key];
    // if (decode) {
    //     return entities.decode(translated);
    // }
    return translated;
}

message.config({
	maxCount: 1,
});
export function showSuccess(msg){
    message.success(msg, 3);
}

export function showInfo(msg){
    message.info(msg, 3);
}

export function showError(msg){
    message.error(msg, 3);
}

export function openLink(url){
    return (
        window.open(url)
    );
}