import axios from 'axios';
import events from '../event-names';
import apiHeaders from '../../kado/utils';
import Config from '../../cankado-config';


export function FetchTranslation(lang,language) {
    return (dispatch) => {
        dispatch({
            meta: {language: lang, lang: language},
            type: events.TRANSLATION_FETCH,
            //Enrep_Dashboard
            payload: axios.get([`${Config.translationFetchUrl}locales/Enrep_Dashboard/${lang}/translation.json?`, Math.random()].join()),
        });
    }
}
