import eventNames from '../../actions/event-names';
import { updateTransalation, updateLanguage } from '../../i18'
const initialState = {
    language: 'en',
    data: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case `${eventNames.TRANSLATION_FETCH}_FULFILLED`: {
        state.language = action.meta.lang? action.meta.lang: state.language;
        state.data[action.meta.language] = action.payload.data;
        updateTransalation(action.meta.language, action.payload.data);
        return {
            ...state,
        };
    }

    default:
        return state;
    }
}
