import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FetchTranslation } from '../actions/translation/';

import locations from '../router/locations';
import 'antd/dist/antd.css';
const Home = lazy(() => import('../pages/'));

class routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        window.location.hash = ""
        this.props.dispatch(FetchTranslation('dev'))
        this.props.dispatch(FetchTranslation('en'))
        this.props.dispatch(FetchTranslation('de'))
    }
    render() {  
        return (
            <Router>
                <Suspense fallback={<div></div>}>
                    <HashRouter>
                        <Switch>
                            <Route path={locations.root} component={Home}/>
                        </Switch>
                    </HashRouter>
                </Suspense>
          </Router>
        );
    }
}
export default connect(store => ({
    user: store.user,
    translations: store.translations,
}))(routes);