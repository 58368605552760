import events from '../../actions/event-names';
import Config from '../../cankado-config';
import locations from '../../router/locations';


const initialState = {
    modalShow: false,
};

function getHeader(action) {
    return {
        authorization: [action.payload.data.token_type, action.payload.data.access_token].join(' '),
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    // CHECK LOGIN
    case `${events.CHECK_LOGIN}_PENDING`: {
        return {
            ...state,
            isCheckLoginComplete: 'PENDING',
        };
    }
    case `${events.CHECK_LOGIN}_FULFILLED`: {
        if (action.payload.data.user_type === 'kadoAdmin') {
            // webSocket(action.payload.data.uuid, action.payload.data);
            document.cookie = `i18next=${action.payload.data.language}; expires=Thu, 18 Dec 2019 12:00:00 UTC`;
            return {
                ...state,
                isCheckLoginComplete: 'FULFILLED',
                headers: {
                    ...getHeader(action),
                },
                ...action.payload.data,
            };
        } else {
            window.location.href = Config.siteRoot;
        }
        return {
            ...state,
            isCheckLoginComplete: 'REJECTED',
        };
    }
    case `${events.CHECK_LOGIN}_REJECTED`: {
        window.location.href = Config.siteRoot;
        return {
            ...state,
            isCheckLoginComplete: 'REJECTED',
        };
    }

    //  LOGOUT
    case `${events.LOGOUT}_PENDING`: {
        return {
            ...state,
        };
    }
    case `${events.LOGOUT}_FULFILLED`: {
        window.location.href = Config.logoutUrl
        return {
            ...state,
        };
    }
    case `${events.LOGOUT}_REJECTED`: {
        return {
            ...state,
        };
    }
    case `${events.SHOW_DRAWER}`: {
        var currentShowState = state.drawerShow;
        state.drawerShow = !currentShowState;

        return {
            ...state,
        };
    }

    // TO SHOW ADD NEW MODAL
    case `${events.SHOW_ADD_MODAL}`: {
        var currentModalState = state[action.meta];
        console.log("modalstate",action.meta)
        state[action.meta] = !currentModalState;

        return {
            ...state,
        };
    }
    


    //Language list
    case `${events.SUPPORTED_LANGUAGES_FETCH}_FULFILLED`: {
        return {
            ...state,
            supportedLanguages:action.payload.data
        };
    }
    case `${events.TIGGER_MODAL}`: {
        return {
            ...state,
            modalShow: action.meta
        };
    }
    
    
    default:
        return state;
    }
}
