import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import Translations from './translations';
import User from './user';

export default combineReducers({
    router: routerReducer,
    User: User,
    translations: Translations,
});
