export default {
    // AUTH
    CHECK_LOGIN: 'CHECK_LOGIN',
    UPDATE_CHECK_LOGIN: 'UPDATE_CHECK_LOGIN',
    LOGOUT: 'LOGOUT',
    TRANSLATION_FETCH: 'TRANSLATION_FETCH',
    FETCH_ALL_LISTS: 'FETCH_ALL_LISTS',
    CHANGE_SEARCH_TYPE: 'CHANGE_SEARCH_TYPE',
    CHANGE_SEARCH_KEY: 'CHANGE_SEARCH_KEY',
    DELETE_USER: 'DELETE_USER',
    SHOW_DRAWER: 'SHOW_DRAWER',
    SUPPORTED_LANGUAGES_FETCH: 'SUPPORTED_LANGUAGES_FETCH',
    TIGGER_MODAL: "TIGGER_MODAL",
}