import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import Router from './router';
import store from './store';
import NetworkService from './middlewares/network';
import './index.scss';


NetworkService.setupInterceptors(store);

ReactDOM.render(
    <Provider store={store}>
        <Router />
    </Provider>,
document.getElementById('root')
);
