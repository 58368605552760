import axios from 'axios';
// import Config from '../cankado-config';

export default {
    setupInterceptors: (store) => {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.config.url.indexOf('/checklogin') && error.response && (401 === error.response.status || 403 === error.response.status)) {
                window.location.href = '/';
            } else if(error.config.method === 'delete' && error.config.url.indexOf('/checklogin') && 404 === error.response.status) {
                window.location.hash = '/';
            } 
            return Promise.reject(error);
        });
    }
};
